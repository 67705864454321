<template>
<div>
  <v-app-bar
      color="deep-purple accent-4"
      :fixed="true"
      dense
      dark>
    <div style="margin-right: 15px;" @click="back">
      <v-icon>mdi-chevron-left</v-icon>
    </div>
    <v-toolbar-title>TMS Order Info</v-toolbar-title>
  </v-app-bar>

  <div
    style="height: calc(100% - 50px - 56px); overflow-y: auto; margin-top: 48px; margin-bottom: 56px; width: 100%;">

  <v-tabs style="width: 100%;"
    grow
    v-model="tab_select">
    <v-tab>Order Info</v-tab>
    <v-tab>Goods Info</v-tab>
    <v-tab>Task Info</v-tab>
  </v-tabs>

  <div style="padding: 8px;"></div>

  <template v-if="tab_select == 0">
    <v-simple-table
      dense
    >
      <template v-slot:default>
        <tbody>
          <tr>
            <th>ID</th>
            <td>{{ tms_order.id }}</td>
          </tr>
          <tr>
            <th>Order Number</th>
            <td>{{ tms_order.order_number }}</td>
          </tr>
          <tr>
            <th>Order Reference</th>
            <td>{{ tms_order.order_reference }}</td>
          </tr>
          <tr>
            <th>Client</th>
            <td>{{ tms_order.client_code }} - {{ tms_order.client_name }}</td>
          </tr>
          <tr>
            <th>Contact</th>
            <td>{{ tms_order.contact }}</td>
          </tr>
          <tr>
            <th>Product Type</th>
            <td>{{ tms_order.product_type_str }}</td>
          </tr>
          <tr v-if="tms_order.product_type == 4">
            <th>Screening</th>
            <td v-if="tms_order.screening == 0">NO</td>
            <td v-if="tms_order.screening == 1">EDD</td>
            <td v-if="tms_order.screening == 2">X-RAY</td>
            <td v-if="tms_order.screening == 3">EDD And X-RAY</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </template>

  <template v-if="tab_select == 1">
    <template v-for="(g, index) in tms_goods">
      <v-simple-table
        :key="g.id"
        dense
      >
        <template v-slot:default>
          <tbody>
            <tr>
              <th>Goods ID</th>
              <td>{{ g.id }}</td>
            </tr>
            <tr>
              <th>Comments</th>
              <td>{{ g.comments }}</td>
            </tr>
            <tr>
              <th>Packing Type</th>
              <td>{{ g.packing_type }}</td>
            </tr>
            <tr>
              <th>Parts</th>
              <td>{{ g.parts }}</td>
            </tr>
            <tr>
              <th>Weight(kg)</th>
              <td>{{ g.weight }}</td>
            </tr>
            <tr>
              <th>Volume Weight(kg)</th>
              <td>{{ g.volume_weight }}</td>
            </tr>
            <tr>
              <th>Volume(m3)</th>
              <td>{{ g.volume }}</td>
            </tr>
            <tr>
              <th>Article Weight(kg)</th>
              <td>{{ g.article_weight }}</td>
            </tr>
            <tr>
              <th>Depth(cm)</th>
              <td>{{ g.depth }}</td>
            </tr>
            <tr>
              <th>Width(cm)</th>
              <td>{{ g.width }}</td>
            </tr>
            <tr>
              <th>Height(cm)</th>
              <td>{{ g.height }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>

      <div v-if="index + 1 < tms_goods.length" :key="g.id + 'hr'">
        <hr style="margin: 10px;">
      </div>
    </template>
  </template>

  <template v-if="tab_select == 2">
    <template v-for="(t, index) in tms_task">
      <v-simple-table
        :key="t.id"
        dense
      >
        <template v-slot:default>
          <tbody>
            <tr>
              <th>Task Type</th>
              <td>{{ t.task_type_str }}</td>
            </tr>
            <tr>
              <th>Begin</th>
              <td>{{ t.date_time_begin }}</td>
            </tr>
            <tr>
              <th>End</th>
              <td>{{ t.date_time_end }}</td>
            </tr>
            <tr>
              <th>Reference</th>
              <td>{{ t.our_reference }}</td>
            </tr>
            <tr>
              <th>Instruction</th>
              <td>{{ t.instruction }}</td>
            </tr>
            <tr>
              <th>Name</th>
              <td>{{ t.name }}</td>
            </tr>
            <tr>
              <th>Street</th>
              <td>{{ t.street }}</td>
            </tr>
            <tr>
              <th>Number</th>
              <td>{{ t.number }}</td>
            </tr>
            <tr>
              <th>Postal Code</th>
              <td>{{ t.postal_code }}</td>
            </tr>
            <tr>
              <th>Place</th>
              <td>{{ t.place }}</td>
            </tr>
            <tr>
              <th>Country</th>
              <td>{{ t.country }}</td>
            </tr>
            <tr>
              <th>Country Code</th>
              <td>{{ t.country_code }}</td>
            </tr>
            <tr>
              <th>Status</th>
              <td @click="goTmsTaskStatus(t.id)"><a href="javascript:;">{{ t.status_str }}</a></td>
            </tr>


          </tbody>
        </template>
      </v-simple-table>

      <div v-if="index + 1 < tms_task.length" :key="t.id + 'hr'">
        <hr style="margin: 10px;">
      </div>
    </template>
  </template>

  </div>

  <v-snackbar
    v-model="snackbar"
    :timeout="snackbar_timeout"
  >
    {{ snackbar_txt }}
    <v-btn
      style="display: none;"
      color="pink"
      text
      @click="snackbar = false"
    >
      Close
    </v-btn>
  </v-snackbar>
</div>
</template>

<script>
import { get } from '@/utils/request.js'
import {EH_MOBILE_GLOBAL} from '@/helper.js'

export default {
  data () {
    return {
      snackbar: false,
      snackbar_txt: '',
      snackbar_timeout: 1500,

      show_no_data: false,

      tab_select: 0,

      // page: 1,
      tms_route_id: null,
      tms_order_id: null,
      has_next_page: true,
      tms_order: {},
      tms_goods: [],
      tms_task: [],
    }
  },
  watch: {
    tab_select (newval, oldval) {
      console.log([newval, oldval])
    },
  },
  methods: {
    getTmsOrderDetail: function () {
      let url = EH_MOBILE_GLOBAL.get_tms_order_detail + '?tms_route_id=' + this.tms_route_id + '&tms_order_id=' + this.tms_order_id
      get(url).then((response) => {
        let data = response.data
        if (data.status != 'success') {
          this.snackbar_txt = data.reason
          this.snackbar = true
          return
        }

        this.tms_order = data.order
        this.tms_goods = data.goods
        this.tms_task = data.tasks

        console.log(this.tms_task)
      })
    },
    back: function () {
      this.$router.go(-1)
    },
    goTmsTaskStatus (tms_order_task_id) {
      let path = '/tms/order/task/status/' + this.tms_route_id + '/' + this.tms_order_id + '/' + tms_order_task_id
      this.$router.push({
        path: path
      })
    }
  },
  created () {
    this.tms_route_id = this.$route.params.tms_route_id
    this.tms_order_id = this.$route.params.tms_order_id

    this.getTmsOrderDetail()
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
